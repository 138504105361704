import "regenerator-runtime/runtime";
import { ethers } from "ethers";
import { parseUnits, hexlify, hashMessage } from "ethers/lib/utils";

let provider;
let signer;

document.addEventListener("DOMContentLoaded", loadApp());

async function loadApp() {
  // provider = (window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, "any") : new ethers.providers.Web3Provider(window.SubWallet);
  processAction();
}

async function loadWallet() {
  signer = provider.getSigner();
  if (!signer) window.location.reload();
  await provider.send("eth_requestAccounts", []);
}

async function processAction() {
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get("action");
  const message = urlParams.get("message");
  const chainId = urlParams.get("chainId") || 1;
  const to = urlParams.get("to");
  const value = urlParams.get("value");
  const data = urlParams.get("data") || "";
  const gasLimit = urlParams.get("gasLimit") || undefined;
  const gasPrice = urlParams.get("gasPrice") || undefined;
  const wallet = urlParams.get("wallet") || "metamask";

  if (wallet === "subwallet") {
    provider = new ethers.providers.Web3Provider(window.SubWallet);
    console.log("zap", provider);
    loadWallet();
    console.log(signer.getAddress());
  }

  if (wallet === "metamask") {
    await window.ethereum.enable();
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    console.log("zap", provider);
    loadWallet();
    console.log(signer.getAddress());
  }

  if (action === "sign" && message) {
    return signMessage(message);
  }

  if (action === "send" && to && value) {
    return sendTransaction(chainId, to, value, gasLimit, gasPrice, data);
  }
  if (action === "verify" && message) {
    return verify(message);
  }

  displayResponse("Invalid URL");
}

async function sendTransaction(chainId, to, value, gasLimit, gasPrice, data) {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const network = await provider.getNetwork();
    console.log(network, "tes", network.chainId.toString() !== chainId);
    console.log(network.chainId.toString());
    console.log(chainId);
    if (network.chainId.toString() !== chainId) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${parseInt(chainId, 10).toString(16)}` }], // chainId must be in hexadecimal numbers
      });
    }
    const from = await signer.getAddress();
    const tx = await signer.sendTransaction({
      from,
      to,
      value: parseUnits(value, "wei"),
      gasLimit: gasLimit ? hexlify(Number(gasLimit)) : gasLimit,
      gasPrice: gasPrice ? hexlify(Number(gasPrice)) : gasPrice,
      data: data ? data : "0x",
    });
    console.log({ tx });
    displayResponse(
      "Transaction sent.<br><br>Copy to clipboard then continue to App",
      tx.hash
    );
  } catch (error) {
    console.log(error);
    copyToClipboard("error");
    displayResponse("Transaction Denied");
  }
}

async function signMessage(message) {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const signature = await signer.signMessage(message);
    console.log({ signature });
    displayResponse(
      "Signature complete.<br><br>Please press continue",
      signature
    );
  } catch (error) {
    copyToClipboard("error");
    displayResponse("Signature Denied");
  }
}

async function verify(message) {
  try {
    if (
      typeof window.ethereum !== undefined ||
      typeof window.SubWallet !== undefined
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const actualAddress = await signer.getAddress();
      const signature = await signer.signMessage(message);
      const account = ethers.utils.recoverAddress(
        hashMessage(message),
        signature
      );
      if (actualAddress == account) {
        displayResponse("Verification has been completed.", account);
      } else {
        throw new Error(
          "Actual address mismatch with recovered address from signature."
        );
      }
    } else {
      throw new Error("No injected web3 found");
    }
  } catch (error) {
    console.log(error);
    console.log(error.message);
    copyToClipboard("error: ", error.message);
    displayResponse("Verify Failed Error: " + error.message);
  }
}

async function copyToClipboard(response) {
  try {
    // focus from metamask back to browser
    window.focus();
    // wait to finish focus
    await new Promise((resolve) => setTimeout(resolve, 500));
    // copy tx hash to clipboard
    await navigator.clipboard.writeText(response);
    var element = document.getElementById("response-button");
    element.style.display = "none";
    // document.getElementById("response-button").innerHTML = "Done";
    window.close();
  } catch {
    // for metamask mobile android
    const input = document.createElement("input");
    input.type = "text";
    input.value = response;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    input.style = "visibility: hidden";
    var element = document.getElementById("response-button");
    element.style.display = "none";
    // document.getElementById("response-button").innerHTML = "Done";
    window.close();
  }
}
function deviceChecker(response) {
  copyToClipboard(response);
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.location.replace("COSMIZE://");
    setTimeout(() => {
      window.location.replace(
        "https://apps.apple.com/us/app/cosmize/id6446470666"
      );
    }, 1000);
  } else if (/android/i.test(navigator.userAgent)) {
    setTimeout(() => {
      alert("Please continue on COSMIZE.");
    }, 1000);
  } else {
    setTimeout(() => {
      alert("Please continue on COSMIZE.");
      console.log("Not redirected to mobile");
    }, 1000);
  }
}

function displayResponse(text, response) {
  console.log(text, "----", response);
  // display error or response
  const responseText = document.getElementById("response-text");
  responseText.innerHTML = text;
  responseText.className = "active";

  if (response) {
    // display button to copy tx.hash or signature
    const responseButton = document.getElementById("response-button");
    responseButton.className = "active";
    responseButton.onclick = () => deviceChecker(response);
  }
}
